import { FormBuilder } from './form-builder';
import TintEcommerceMeasure from './analytics/tint-ecommerce-measure';

const init = () => {
  window.Tint = {
    TintEcommerceMeasure: TintEcommerceMeasure,
    FormBuilder: new FormBuilder(),
  };

  window.Tint.FormBuilder.init();
};

if (typeof window !== 'undefined') {
  if (window._env_) {
    init();
  } else {
    const script = document.createElement('script');
    script.src = `${new URL(document.currentScript.src).origin}/dist/env-config.js`;
    script.async = true;
    script.onload = init;
    document.head.appendChild(script);
  }
}
