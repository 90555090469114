import Kinesis from './kinesis';
import { v4 as uuidv4 } from 'uuid';
import anonymousIdentity from './anonymous-identity';

export default class TintEcommerceClient {
  constructor() {
    this.kinesis = new Kinesis('analytics-stream');
    this.database = 'tint-analytics';
    this.table = 'ecommerce_events';
  }

  async track(name, params) {
    const user_id = anonymousIdentity();
    const properties = {
      ...params,
      uuid: user_id,
      id: uuidv4(), // unique ID
    };
    if (window.TINT_LOG) {
      console.log(`tracking: ${name} - ${JSON.stringify(properties)}`);
    }
    return await this.kinesis.send(JSON.stringify(this.trackEvent(name, params)));
  }

  async trackBulk(records) {
    const user_id = anonymousIdentity();
    let trackRecords = records.map(e => {
      const event = {
        ...e.params,
        uuid: user_id,
        id: uuidv4(),
      };
      const trackEvent = this.trackEvent(e.name, event);
      if (window.TINT_LOG) {
        console.log(`tracking bulk ${JSON.stringify(trackEvent)}`);
      }
      return trackEvent;
    });
    trackRecords = trackRecords.map(e => JSON.stringify(e));
    return await this.kinesis.send_bulk(trackRecords);
  }

  trackEvent(name, params) {
    return {
      type: 'track',
      event: name,
      database: this.database,
      table: this.table,
      properties: params,
      timestamp: new Date().toISOString(),
    };
  }
}
