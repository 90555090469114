import TintEcommerceClient from './src/tint-ecommerce-client';
import { v4 as uuidv4 } from 'uuid';
import { object, string, number, date, InferType } from 'yup';
import Big from 'big.js';

class TintEcommerceMeasure {
  constructor(teamId, init = true) {
    this.client = new TintEcommerceClient();
    this.teamId = teamId;
  }

  init(title = document.title, url = document.location.href) {
    this.client.track('ecommerce-page-loaded', {
      team_id: this.teamId,
      url: url,
      title: title,
    });
  }

  addToCart(items, txId = uuidv4()) {
    this._validate(items);
    const events = items.map(item => {
      return {
        name: 'ecommerce-add-to-cart-item',
        params: {
          ...item,
          transaction_id: txId,
          team_id: this.teamId,
        },
      };
    });
    this.client.trackBulk(events);
  }

  checkout(items, txId = uuidv4()) {
    this._validate(items);
    const events = items.map(item => {
      return {
        name: 'ecommerce-checkout-item',
        params: {
          ...item,
          transaction_id: txId,
          team_id: this.teamId,
        },
      };
    });
    const consolidatedOrderAmount = items.reduce((total, item) => {
      return total + item.price;
    }, 0);
    const consolidatedOrderQuantity = items.reduce((total, item) => {
      return total.plus(new Big(item.quantity));
    }, new Big(0));
    const orderEvent = {
      name: 'ecommerce-checkout-order',
      params: {
        transaction_id: txId,
        team_id: this.teamId,
        price: consolidatedOrderAmount.toString(),
        quantity: consolidatedOrderQuantity,
      },
    };
    events.push(orderEvent);
    this.client.trackBulk(events);
  }

  _validate(items) {
    let orderItemSchema = object({
      price: number().required(),
      quantity: number().required(),
      sku: string().required(),
      currency: string()
        .length(3)
        .required(),
    });
    items.map(item => {
      orderItemSchema.validate(item);
    });
  }
}

window.Tint = window.Tint || {};

window.Tint.TintEcommerceMeasure = TintEcommerceMeasure;

export default TintEcommerceMeasure;
