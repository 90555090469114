import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export default function anonymousIdentity() {
  var uuid = Cookies.get('tint-anonymous-uid');
  if (!uuid) {
    uuid = uuidv4();
    let cookieParams = {
      expires: 365,
      path: '/',
      sameSite: 'strict',
    };
    if (get_current_domain() != 'localhost') {
      cookieParams['domain'] = '.' + get_current_domain();
    }
    Cookies.set('tint-anonymous-uid', uuid, cookieParams);
  }
  return uuid;
}

function get_current_domain() {
  var i = 0,
    domain = document.domain,
    p = domain.split('.'),
    s = '_gd' + new Date().getTime();
  while (i < p.length - 1 && document.cookie.indexOf(s + '=' + s) == -1) {
    domain = p.slice(-1 - ++i).join('.');
    document.cookie = s + '=' + s + ';domain=' + domain + ';';
  }
  return domain;
}
